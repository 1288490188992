<template>
  <div class="events">
    <h1>Events for good</h1>
    <EventCard v-for="event in events" :key="event.id" :event="event" />
  </div>
</template>

<script>
// @ is an alias to /src
import EventCard from "@/components/EventCard.vue";
import EventService from "@/services/EventService.js";

export default {
  name: "EventList",
  components: {
    // this register EventCard as a child component of this page component
    EventCard,
  },
  data() {
    return {
      events: null,
    };
  },
  created() {
    EventService.getEvents()
      .then((response) => {
        console.log(response.data);
        this.events = response.data;
      })

      .catch((err) => {
        console.log(err);
      });
    // ${binding.visualPotential ==='medium'? '#euhfgk':(binding.visualPotential ==='low'? '#dfgkjhg' : '#gfdhftgh')}
  },
};
</script>
<style scoped>
.events {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
