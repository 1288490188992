import axios from "axios";
// this is the single axios instance that we can use for our entire app
const apiClient = axios.create({
  baseURL: `https://my-json-server.typicode.com/Code-Pop/Real-World_Vue-3`,
  withCredentials: false, // This is the default
  // we can add some authentication and configurations for communicating with our server
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
export default {
  getEvents() {
    // this is added onto base url so we can grap all events
    return apiClient.get("/events");
    // if you want data from single event you need to call this function get events with the evemnt id
    // EventServices.getEvents(this.id)
  },
  getEvent(id) {
    // this is added onto base url so we can grap all events
    return apiClient.get("/events/" + id);
    // if you want data from single event you need to call this function get events with the evemnt id
    // EventServices.getEvents(this.id)
  },
};
