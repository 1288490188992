<template>
  <div>
    <h1>{{ event.title }}</h1>
    <p>{{ event.time }} on {{ event.date }} @ {{ event.location }}</p>
    <p>{{ event.description }}</p>
  </div>
</template>

<script>
import EventService from "@/services/EventService.js";

export default {
  props: ["id"],
  data() {
    return {
      event: null,
    };
  },
  created() {
    // we gonna fetch this event by ID
    EventService.getEvent(this.id)
      .then((response) => {
        console.log(response.data);
        this.event = response.data;
      })

      .catch((err) => {
        console.log(err);
      });
  },
};
</script>
>
